*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    background-color: black;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
#logo{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    z-index: 2;
}
#logo img{
    width: 150px;
    height: 150px;
}
#menu{
    margin: 0;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}
#menu p{
    text-align: right;
    color: white;
    font-family: 'Arimo', sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 40px 0;
}
#menu a:link{
    text-decoration: none;
}

#menu p:hover{
    color: #f4bc42;
}

.hide-element{
    opacity: 0;
    transition: visibility 0s, opacity 3s linear;
}
.hide-element2{
    opacity: 0;
    transition: visibility 0s, opacity 3s linear;
}

.loading-bar{
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    will-change: transform;
}
.loading-bar.ended{
    transform-origin: top right;
    transition: transform 1.5s ease-in-out;
}

#magicMouseCursor{
    opacity: 0;
    transition: visibility 0s, opacity 3s linear;
}
#magicPointer{
    opacity: 0;
    transition: visibility 0s, opacity 3s linear;
}

@media only screen and (max-width: 530px) {
    #logo{
        margin: 0;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: 3;
    }
    #logo img{
        width: 100px;
        height: 100px;
    }
    #menu{
        margin: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 3;
        width: 100%;
    }
    #menu p{
        text-align: center;
    }
}